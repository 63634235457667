import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/Layout'
import { withPreview } from 'gatsby-source-prismic'
import tw from 'twin.macro' //eslint-disable-line

const BrandPage = ({ data }) => {

  const brand = data.prismicBrand.data;

  const title = brand.title.text;
  const description = brand.description.html;
  const hero = brand.project_hero_image.url;
  const hero_alt_text = brand.project_hero_image.alt ? brand.project_hero_image.alt : 'Thumbnail';
  const gallery = brand.gallery;
  const details = brand.details;

  return (
    <Layout>
      <div className="extraTop">
        <div>
          <h1 tw='text-center my-8'>{ title }</h1>
          <img tw="px-5" src={ hero } alt='Brand Hero' />
          <div tw="md:grid md:grid-cols-2 max-w-6xl mx-auto gap-3 md:gap-5 py-12 px-5">
            <dl>
              { details.map((detail, index) => {
                return (
                  <div className="detail-item" key={ index } tw="flex py-1 text-sm items-baseline">
                    <dd tw="font-serif italic pr-3" dangerouslySetInnerHTML={ { __html: detail.name.html } } key={ index } ></dd>
                    <dt dangerouslySetInnerHTML={ { __html: detail.content.html } }></dt>
                  </div>
                )
              }) }
            </dl>

            <div dangerouslySetInnerHTML={ { __html: description } } tw='text-sm leading-loose pt-12 md:pt-0'></div>

          </div>

          <div tw="grid grid-cols-3 sm:grid-cols-4 gap-3 md:gap-5 max-w-6xl mx-auto pb-10 px-5">
            { gallery.map((item, index) => {
              return <img key={ index } src={ item.item.thumbnails.thumbnail.url } alt={ hero_alt_text } />
            }) }
          </div>
        </div>
      </div>
    </Layout>
  )
}


export const query = graphql`
  query($id: String!) {
    prismicBrand(id: {eq: $id}) {
    id
    data {
      title {
        raw
      }
      project_hero_image {
        url
        alt
      }
      page_title {
        raw
      }
      page_description {
        raw
      }
      gallery {
        item {
          url
          alt
          dimensions {
            height
            width
          }
          thumbnails {
            thumbnail {
              alt
              copyright
              url
            }
          }
        }
        caption {
          text
        }
      }
      details {
        name {
          html
        }
        content {
          html
        }
      }
      description {
        html
        raw
        text
      }
    }
  }
  }
`


export default withPreview(BrandPage)
